import router from './router';

function getTokenFromURL() {
    const params = new URLSearchParams(window.location.search);
    return params.get('token');
}

router.beforeEach((to, from, next) => {
    const token = getTokenFromURL() || sessionStorage.getItem('accessToken');
    const isChild = new URLSearchParams(window.location.search).get('isChild');

    if (token) {
        // 如果 URL 中存在 token 并且当前路由是根路径或 /login，则跳转到 /home/chat
        sessionStorage.setItem('accessToken', token);
        if (to.path === '/' || to.path === '/login') {
            next({ path: '/home/chat' });
        } else {
            next(); // 其他路由正常跳转
        }
    } else {
        // 如果没有 token 并且当前路由不是 /login，跳转到 /login 页面
        if (to.path !== '/login') {
            next({ path: '/login' });
        } else {
            next(); // 允许正常跳转到 /login
        }
    }
});

router.afterEach(() => {
    // 可以在这里添加一些全局的后置处理逻辑，比如清除 loading 状态
});
